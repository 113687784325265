
<template>
    <div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
        <div class="col-12" style="padding: 0 !important;">
            <StockHeader :type="$route.params.type" :name="$route.params.name"></StockHeader>
            <StockCharts></StockCharts>
        </div>
    </div>
</template>

<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import StockHeader from '@/components/Mobile/Market/Profile/Stock/Header';
import StockCharts from '@/components/Mobile/Market/Profile/Stock/Charts';
export default {
    name: 'StockChartsView',
    components: {
        StockHeader,
        StockCharts,
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    },
}
</script>